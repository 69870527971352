import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브 최대주주, 카카오인베스트먼트에서 임직원 참여한 신규 투자조합으로… 구주 매각 방식으로 진행",
  "| 신규 투자사도 지분 인수 참여 예정",
]

const content = () => (
  <div>
    <br />

    <p>
      스테이지파이브(대표 서상원)는 최대주주인 카카오인베스트먼트와 최대주주 변경을 수반하는 지분 매각 계약이 체결되었다고 18일
      밝혔다. 이에 따라 임직원을 포함하여 구성된 신규 투자조합이 스테이지파이브의 최대주주로 올라서며 책임경영을 위한 지배구조로
      개편될 예정이다.
      <br />
      <br />
      스테이지파이브는 2017년이후로 카카오인베스트먼트가 40% 이상의 지분을 보유하여 카카오의 계열회사로 분류되어 왔다.
      <br />
      <br />
      신규 투자조합은 카카오인베스트먼트가 보유한 주식 35만 5천여주 중 27만 7천여주를 취득하는 계약을 체결했다. 해당 계약에 따르면
      조합이 스테이지파이브의 주식 22만 5백여주(20.94%)를 확보하며 최대주주가 된다. 새로 참여하게 되는 투자사는 5만 6천
      5백여주(5.36%)를 취득하게 될 예정이다.
      <br />
      <br />
      이에 따라, 스테이지파이브의 지배구조에도 변동이 있을 예정이다. 카카오인베스트먼트는 최대주주의 지위에서 내려오게 되고, 8.30%
      지분율을 가지게 된다. 따라서 스테이지파이브는 카카오의 계열회사 및 대기업 분류에서 제외될 예정이다.
      <br />
      <br />
      스테이지파이브의 새로운 최대주주는 스테이지파이브의 경영권 및 신사업 강화를 위해 결성된 조합으로, 앞서 언급한 바와 같이
      경영진들이 주축이 되어 해당 조합의 구성원으로 직접 참여함으로써 책임경영의 소임을 다하겠다는 의지를 공고히 하였다.
      <br />
      <br />
      한편, 스테이지파이브는 신한투자증권을 대표 주관사, NH투자증권 공동 주관사로 선정하고 상장을 준비 중이다.
      <br />
      <br />
    </p>
  </div>
)

const press49 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 12월 18일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 책임경영 의지 밝혀… 지배구조 개편"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press49
